<template>
  <div id="app" class="container">
    <router-view />
  </div>
</template>

<script>
export default {
  methods: {
    /**
     * We listen to the resize event
      */
    setVhValue() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  },
  mounted() {
    this.setVhValue();
    window.addEventListener('resize', this.setVhValue);
  }
};
</script>

<style lang="scss">
@import "@/styles/index.scss";
</style>
