<template>
  <div class="scanner-promo">
    <div class="message-wrapper" v-if="showError">
      <div class="message message-error">
        <div class="message__icon">
          <img src="@/assets/warn-icon.svg" alt="">
        </div>
        <div class="message__content">
          <p class="message__heading">Ошибка загрузки</p>
          <p class="message__text">
            Что-то пошло не так, пожалуйста повторите загрузку
          </p>
        </div>
      </div>
    </div>

    <div class="message-wrapper" v-if="showSuccess">
      <div class="message message-success">
        <div class="message__icon">
          <img src="@/assets/ok-icon.svg" alt="">
        </div>
        <div class="message__content">
          <p class="message__heading">Карта отсканирована</p>
          <p class="message__text">
            Номер карты: <span class="text-dashed" v-text="cardNumberFormatted" />
          </p>
        </div>
      </div>
    </div>

    <section>
      <div class="content">
        <p class="heading">
          Клубная карта <span class="c-green">GreenGo&nbsp;Fitness</span><br class="dn-sm" />
          в&nbsp;твоём iPhone
        </p>
        <p class="heading-2">
          Загрузи карту в<br class="dn-sm" />
          Apple Wallet
        </p>

        <div class="picture">
          <img src="@/assets/green-go-mockup-new.png" alt="Phone mockup" v-if="!showScanner">
          <StreamBarcodeReader
            v-if="showScanner"
            @decode="onDecode"
            @load="onLoad"
          ></StreamBarcodeReader>
        </div>

        <div class="actions">
          <div class="action-col">
            <button type="button" class="button" @click="() => { this.showScanner = !this.showScanner; }">
              <strong>Сканировать</strong><br> карту клуба
            </button>
            <!--<p>
              <strong>Камерой</strong> смартфона <br/>
              через <strong>Safari</strong>
            </p>-->
          </div>
        </div>
      </div>
    </section>

    <!-- <a :href="downloadLink"
       style="position: absolute; opacity: 0; width: .1px; height: .1px; z-index: -1;"
       v-if="downloadLink"
       ref="downloadButton"
       download="pass.pkpass">Скачать</a> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { StreamBarcodeReader } from "vue-barcode-reader";
import Vue from "vue";

export default {
  name: 'Home',
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      showError: false,
      showSuccess: false,
      showScanner: false,
      selectedType: 'none',
      imageLoaded: false,
      cardNumber: '',
      timer: null,
      // downloadLink: ''
    };
  },
  computed: {
    cardNumberFormatted() {
      return !this.cardNumber ? '' : this.cardNumber
        .match(/.{1,4}/g)
        .join(' ');
    }
  },
  methods: {
    onDecode(decodedString) {
      this.cardNumber = decodedString;

      if (!this.cardNumber) {
        this.onError();
        return;
      }

      this.showSuccess = true;
      this.showError = false;
      this.delayedPopupHide();

      this.showScanner = false;
      // this.downloadLink = './download.php?number=' + this.cardNumber;
      Vue.nextTick(() => {
        location.href = './download.php?number=' + this.cardNumber;
        // if (!this.$refs.downloadButton) return;
        // this.$refs.downloadButton.click();
      });

      // console.log(decodedString);
      // this.selectedType='none';
    },
    onError() {
      this.showSuccess = false;
      this.showError = true;

      this.delayedPopupHide();
    },
    delayedPopupHide() {
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.showError = false;
        this.showSuccess = false;
      }, 3000);
    },
    onLoad(...args) {
      console.log(args);
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.scanner-wrapper {
  //position: fixed;
  //top: 0;
  //right: 0;
  //bottom: 0;
  //left: 0;
  //z-index: 99999;
}

.scanner-promo {
  section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    @include vh(min-height, 54);
  }

  .content {
    width: 70%;
  }

  .heading {
    font-size: 46px;
    line-height: 1.16;
    margin: 10px 0;
  }

  .heading-2 {
    font-size: 28px;
    line-height: 1.16;
    margin: 40px 0 0;
    color: #474646;
  }

  .actions {
    margin-top: 40px;
  }

  .action-col {
    p, .p {
      display: block;
      color: #474646;
      font-size: 12px;
      margin-top: 10px;
    }
  }

  .message {
    &-wrapper {
      position: fixed;
      top: 26%;
      z-index: 999;
      width: 100%;
      left: 0;
      right: 0;
      padding: 0 20px;
    }

    display: flex;
    align-items: center;
    max-width: 420px;
    margin: auto;
    //left: 20px;
    //right: 20px;
    height: auto;
    padding: 22px;
    background-color: #fff;
    border: 1px solid;
    border-radius: 22px;

    &__content {
      padding-left: 20px;
    }

    &__heading {
      font-weight: bold;
    }

    &__text {
      line-height: 1.6;
    }

    &-success {
      border-color: #00C600;
      color: #00C600;
    }

    &-error {
      border-color: #f54d4f;
      color: #f54d4f;
    }
  }

  .upload-button {
    cursor: pointer;

    input[type=file] {
      position: absolute;
      height: .1px;
      width: .1px;
      opacity: 0;
    }
  }

  .button {
    cursor: pointer;
    display: inline-block;
    border: none;
    background: #00C600;
    border-radius: 8px;
    padding: 10px;
    text-align: left;
    color: #fff;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;

    &:focus {
      outline: none;
      background: darken(#00C600, 8);
    }
  }

  .scanner-container {
  }

  .picture {
    position: absolute;
    height: 530px;
    width: 660px;
    top: -160px;
    right: -180px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      //width: 100%;
      //height: 531px;
      //object-fit: cover;
      //object-position: center center;
    }
  }

  @media (min-width: 1001px) {
    .scanner-container {
      max-width: 320px;
    }
  }
  @media (max-width: 1000px) {
    section {
      flex-direction: column;
    }

    .container {
      justify-content: flex-start;
    }

    .picture {
      @include vh(margin-top, 4.5);
      @include vh(margin-bottom, 4.5);
      @include vh(margin-left, -2);
      @include vh(margin-right, -2);

      &, img {
        position: static;
        transform: none;
        top: 0;
        right: 0;
        width: auto;
        @include vh(height, 46);
      }

      img {
        transform: none;
        position: static;
        width: 100%;
        object-fit: contain;
      }
    }

    .scanner-container {
      width: 65%;
    }

    .content {
      width: 100%;
    }

    .actions {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 0;
    }

    .heading {
      text-align: center;
      padding: 40px 0 0;
      margin: 0;
      font-weight: bold;

      @include vh(font-size, 3.3);
      @include vh(max-height, 16);
    }

    .heading-2 {
      margin: 0;
      padding: 30px 50px 0;
      text-align: center;

      @include vh(font-size, 2.4);
      @include vh(max-height, 10);
    }
  }

  @media (max-width: 340px) {
    .picture {
      //@include vh(margin-top, -3);
      //@include vh(margin-bottom, -3);

      &, img {
        @include vh(height, 58);
      }
    }
  }
}

</style>
